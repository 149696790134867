<template>
    <div class="page-wrapper page-home">
        <!-- Nav Heading Spacer -->
        <div class="header-spacer"></div>

        <!-- Form -->
        <section class="form-wrap">
            <div class="inner site-max-width">
                <div class="titles" v-if="contentIsLoaded">
                    <img
                        src="@/assets/img/logo-dock619.png"
                        alt="Dock619"
                        data-inview="fadeInUp"
                        data-delay="100"
                    />
                    <h3 class="regular-title" data-inview="fadeInUp" data-delay="200">
                        {{ content.page.entete.titre }}
                    </h3>
                    <p class="text" data-inview="fadeInUp" data-delay="400">
                        <span v-html="content.page.entete.texte"></span>
                    </p>
                </div>

                <form
                    class="full-form"
                    name="postuler"
                    action=""
                    method="post"
                    accept-charset="UTF-8"
                    @submit="sendForm($event)"
                    enctype="multipart/form-data"
                    data-inview="fadeInUp"
                    data-delay="500"
                >
                    <input type="hidden" name="action" value="contact-form/send" />

                    <div class="input-row half">
                        <label for="nom">{{ $t('Prénom et nom') }} *</label>
                        <input
                            type="text"
                            id="nom"
                            name="nom"
                            v-model="formData.nom"
                            :placeholder="$t('Prénom et nom') + ' *'"
                            required
                        />
                    </div>

                    <div class="input-row half">
                        <label for="entreprise">{{ $t('Entreprise') }} *</label>
                        <input
                            type="text"
                            id="entreprise"
                            name="entreprise"
                            v-model="formData.entreprise"
                            :placeholder="$t('Entreprise') + ' *'"
                            required
                        />
                    </div>

                    <div class="input-row half">
                        <label for="telephone">{{ $t('Téléphone') }} *</label>
                        <input
                            type="text"
                            id="telephone"
                            name="telephone"
                            v-model="formData.telephone"
                            :placeholder="$t('Téléphone') + ' *'"
                        />
                    </div>

                    <div class="input-row half">
                        <label for="email">{{ $t('Courriel') }} *</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            v-model="formData.courriel"
                            :placeholder="$t('Courriel') + ' *'"
                            required
                        />
                    </div>

                    <div class="input-row half">
                        <label for="date">{{ $t("Date de l'événement") }} *</label>
                        <input
                            class="input-date"
                            type="date"
                            id="date"
                            name="date"
                            v-model="formData.date"
                            :placeholder="'' + $t('Date de l\'événement') + ' *'"
                        />
                    </div>

                    <div class="input-row half">
                        <label for="invites">{{ $t("Nombre d'invités") }} *</label>
                        <input
                            type="number"
                            id="invites"
                            name="invites"
                            v-model="formData.invites"
                            :placeholder="'' + $t('Nombre d\'invités') + ' *'"
                            required
                        />
                    </div>

                    <div class="input-row full">
                        <label for="offre">{{ $t('Choisir une offre') }}</label>
                        <select
                            name="offre"
                            id="offre"
                            v-model="formData.offre"
                            :placeholder="$t('Choisir une offre')"
                        >
                            <option value="">{{ $t('Choisir une offre') }}</option>
                            <option v-for="(item, i) in globals.evenementsEntry.data" :key="i">{{ item.page.title }}</option>
                        </select>
                    </div>

                    <div class="input-row full">
                        <label for="email">{{ $t('Message') }}</label>
                        <textarea
                            name="message"
                            id="message"
                            v-model="formData.message"
                            :placeholder="$t('Message')"
                        ></textarea>
                    </div>

                    <div class="input-wrapper">
                        <input class="site-btn" type="submit" :value="$t('Envoyer')" />
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { mapGetters, mapState } from 'vuex'

import axios from 'axios'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

export default {
    name: 'Soumission',

    data() {
        return {
            formMessage: '',
            formMessageStatus: '',
            isSending: false,
            formData: {
                offre: '',
                date: '',
            },
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    initInViewAnimations()

                    setTimeout(() => {
                        document.querySelector('.input-date').addEventListener('change', () => {
                            document.querySelector('.input-date').classList.add('change')
                        })
                    }, 111)
                }
            },
            immediate: true,
        },
    },

    mounted() {},

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        async sendForm(e) {
            e.preventDefault()

            if (this.isSending) {
                return
            }

            this.isSending = true

            // this.formData[this.content.page.content.page.form.csrfName] = this.content.page.content.page.form.csrf
            this.formData['action'] = 'contact-form/send'
            this.formData['formName'] = 'Soumission'
            this.formData['sujet'] = 'Soumission'

            const formData = new FormData()
            formData.append('formName', this.formData.formName)
            formData.append('sujet', this.formData.sujet)

            formData.append('nom', this.formData.nom)
            formData.append('entreprise', this.formData.entreprise)
            formData.append('telephone', this.formData.telephone)
            formData.append('courriel', this.formData.courriel)
            formData.append('date', this.formData.date)
            formData.append('invites', this.formData.invites)
            formData.append('offre', this.formData.offre)
            formData.append('message', this.formData.message)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            await axios
                .post(
                    `${process.env.VUE_APP_SITE_BASE_URL}/api/send/contact-form`,
                    formData,
                    config
                )
                .then(res => {
                    this.formMessage = 'success'
                    this.formMessageStatus = res.status

                    Swal.fire({
                        text: this.$t('Votre demande de soumission a été envoyé avec succès.'),
                        icon: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                    setTimeout(() => {
                        this.formData = {}

                        this.formMessage = ''
                        this.isSending = false
                    }, 2275)
                })
                .catch(e => {
                    this.formMessage = e

                    setTimeout(() => {
                        this.formData = {
                            fromName: '',
                            fromEmail: '',
                            phone: '',
                            subject: '',
                            message: '',
                        }

                        this.formMessage = ''
                        this.isSending = false
                    }, 2275)
                })
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
